import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useComingSoonProjects } from "../../../hook/useState";
import AiSection from "../../../shared/components/Section";
import { getCountDown, remainTime } from "../../../shared/utils/helper";

const WaitingProjectsComponent = () => {
  const waitingProjects = useComingSoonProjects()

  return (
    <AiSection title="PROJECTS COMING SOON">
      {waitingProjects.length > 0 ?
        <Row className="gx-md-5">
          {
            waitingProjects.map((item, key) => {
              getCountDown(`idOpenTime-${item["contract"]}-${item["openTime"]}`, item.openTime * 1000, function start(job) {
              }, function end(job) {
              })
              return (
                <Col xl="4" md="6" key={key}>
                  <div className="ai-project coming">
                    <Link to={(item.contract && item.contract !== 'TBA') ? `projects/${item.contract}` : `#`} className="ai-project-header" style={{ backgroundImage: `url(${item.logo})` }}>
                      {item.state === "P" ?
                        <>
                          {(item.contract === 'TBA' || item.openTime === 0 || Number(item.rate) === 0) ?
                            <span className="ai-project-status opening">Opens in TBA<b></b></span>
                            : <span className="ai-project-status opening">Opens in <b id={`idOpenTime-${item["contract"]}-${item["openTime"]}`}>{remainTime(item.openTime * 1000)}</b></span>
                          }
                        </> : <></>}
                      <span className="ai-project-symbol">{item.contract !== 'TBA' ? item.symbol : ''}</span>
                      {item.athMultiplier && <div className="ai-project-tag"><small>ATH</small>&nbsp;<b>{item.athMultiplier}x</b></div>}
                      <div className="ai-project-logo mb-3">
                        <img src={item.logo} alt={item.name} />
                      </div>
                      <div className="ai-project-name">{item.name}</div>
                    </Link>
                    <div className="ai-project-socials">
                      {
                        item.pancakeswap &&
                        <a style={{ backgroundColor: '#47d4dc' }} href={item.pancakeswap} target="_blank" rel="noopener noreferrer">
                          <img height="18" src="/images/pancake-swap.png" alt="" />
                        </a>
                      }
                      {
                        item.website &&
                        <a href={item.website} target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a>
                      }
                      {
                        item.twitter &&
                        <a href={item.twitter} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                      }
                      {
                        item.medium &&
                        <a href={item.medium} target="_blank" rel="noopener noreferrer"><i className="fab fa-medium-m"></i></a>
                      }
                      {
                        item.telegram &&
                        <a href={item.telegram} target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram"></i></a>
                      }
                    </div>
                    <div className="ai-project-description">
                      {item.description}
                    </div>

                    <div className="ai-project-detail">
                      <ul>
                        <li>
                          <span>Swap rate</span><br />
                          <b className="nowrap text-white">
                            {!item.contract ? "TBA" :
                              item.contract !== 'TBA' ?
                                item.rate !== 0 ?
                                  (`1 ${item.symbol} = ${item["rate"]} ${item["projectTokenSymbol"]}`)
                                  : 'TBA'
                                : "TBA"}

                          </b>
                        </li>
                        <li>
                          <span>Cap</span><br />
                          <b className="nowrap text-white">
                            {
                              !item.contract ? "TBA" :
                                item.contract !== 'TBA' ?
                                  `${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`
                                  : `TBA`
                            }
                          </b>
                        </li>
                        <li>
                          <span>Access</span><br />
                          <b className="nowrap text-white">
                            {item.isPrivate ? "Private" : "Public"}
                          </b>
                        </li>
                      </ul>
                    </div>
                    {
                      item.button_name && <div className="ai-project-cta text-center">
                        <a href={item.button_url} target="blank" className="ai-btn sm w-auto m-3 primary">
                          <span>{item.button_name}</span>
                        </a>
                      </div>
                    }
                  </div>
                </Col>
              )
            })
          }
        </Row> : <div>No projects currently coming soon</div>
      }
    </AiSection>
  );
};

export default WaitingProjectsComponent;
