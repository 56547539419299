import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useOpeningProjects } from "../../../hook/useState";
import AiSection from "../../../shared/components/Section";

const OpeningProjectsComponent = () => {
  const openingProjects = useOpeningProjects();

  return (
    <AiSection title="PROJECTS OPEN NOW">
      {openingProjects.length > 0 ?
        <Row className="gx-md-5">
          {
            openingProjects.map((item, key) => {
              return (
                <Col xl="4" md="6" key={key}>
                  <div className="ai-project open">
                    <Link to={`projects/${item.contract}`} className="ai-project-header" style={{ backgroundImage: `url(${item.logo})` }}>
                      <span className="ai-project-status opening">Open</span>
                      <span className="ai-project-symbol">{item.contract !== 'TBA' ? item.symbol : ''}</span>
                      {item.athMultiplier && <div className="ai-project-tag"><small>ATH</small>&nbsp;<b>{item.athMultiplier}x</b></div>}
                      <div className="ai-project-logo mb-3">
                        <img src={item.logo} alt={item.name} />
                      </div>
                      <div className="ai-project-name">{item.name}</div>
                    </Link>

                    <div className="ai-project-socials">
                      {
                        item.pancakeswap &&
                        <a style={{ backgroundColor: '#47d4dc' }} href={item.pancakeswap} target="_blank"><img height="18" src="/images/pancake-swap.png" /></a>
                      }
                      {
                        item.website &&
                        <a href={item.website} target="_blank"><i className="fas fa-globe"></i></a>
                      }
                      {
                        item.twitter &&
                        <a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                      }
                      {
                        item.medium &&
                        <a href={item.medium} target="_blank"><i className="fab fa-medium-m"></i></a>
                      }
                      {
                        item.telegram &&
                        <a href={item.telegram} target="_blank"><i className="fab fa-telegram"></i></a>
                      }
                    </div>
                    <div className="ai-project-description">
                      {item.description}
                    </div>
                    <div className="ai-project-detail">
                      <ul>
                        <li>
                          <span>Swap rate</span><br />
                          <b className="nowrap text-white">
                            1 {item.symbol} = {(item["rate"])} {item["projectTokenSymbol"]}
                          </b>
                        </li>
                        <li>
                          <span>Cap</span><br />
                          <b className="nowrap text-white">
                            {`${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`}
                          </b>
                        </li>
                        <li>
                          <span>Access</span><br />
                          <b className="nowrap text-white">
                            {item.isPrivate ? "Private" : "Public"}
                          </b>
                        </li>
                        <li>
                          {
                            item.state == 'O' ?
                              <div className={item.state == 'O' ? 'ai-project-progress-wrap light-progress disabled' : 'ai-project-progress-wrap'}>
                                <div className="mb-2 d-flex justify-content-between align-items-center ai-project-progress-top">
                                  <div className="ai-project-col">Progress</div>
                                  {item.state != 'O' && <div className="ai-project-col ai-project-col-participants text-end">Participants <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>}
                                </div>
                                <div className='ai-project-progress'>
                                  <div className="ai-project-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                  <div className="ai-project-progress-label">

                                    <span className="participants-center" >Allocation round</span>
                                    <span className="participants-center" style={{ top: "8px" }}><b>{item['totalCountUserParticipated']}</b> Participants</span>
                                  </div>
                                </div>
                              </div>
                              :
                              <>
                                <div className={'ai-project-progress-wrap'}>
                                  <div className="mb-2 d-flex justify-content-between align-items-center ai-project-progress-top">
                                    <div className="ai-project-col">Progress</div>
                                    <div className="ai-project-col ai-project-col-participants text-end">Participants <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>
                                  </div>
                                  <div className='ai-project-progress'>
                                    <div className="ai-project-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                    <div className="ai-project-progress-label">
                                      <span><b>{((item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                      {item.state == 'O' && <span className="participants-center" style={{ top: "8px" }}><b>{item['totalCountUserParticipated']}</b> Participants</span>}
                                      <span className="text-allocation"><b>{item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated}</b></span>
                                    </div>
                                  </div>
                                </div>
                              </>
                          }
                        </li>
                      </ul>
                    </div>
                    {
                      item.button_name && <div className="pd-project-cta text-center">
                        <a href={item.button_url} target="blank" className="ai-btn sm w-auto m-3 primary">
                          <span>{item.button_name}</span>
                        </a>
                      </div>
                    }

                  </div>
                </Col>
              )
            })

          }
        </Row> : <div>No projects currently open</div>
      }
    </AiSection>
  );
};

export default OpeningProjectsComponent;
